import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

const Nav = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        generalSettings {
          url
        }
      }
      wpMenu(id: {eq: "dGVybToy"}) {
        menuItems {
          nodes {
            url
            id
            label
          }
        }
      }
    }
  `);

  const { url } = data.wp.generalSettings;
  const menuItems = data.wpMenu.menuItems.nodes.map((item) => ({
    ...item,
    url: item.url.replace(url, ''),
  }));

  return (
    <nav>
      <ul className="menu">
        {menuItems.map((item) => (
          <li key={item.id}>
            <Link to={item.url}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Nav;
