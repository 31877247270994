import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Header = () => {
  const slideShow = useStaticQuery(graphql`
    query {
      backgrounds: allFile(
        filter: { sourceInstanceName: { eq: "backgrounds" } }
      ) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(layout: FIXED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  `);

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    fade: true,
  };

  return (
    <div className="header">
      <Link to="/">
        <StaticImage
          className="logo"
          src="../assets/img/logo.png"
          title="Vrať se zpět na hlavní stranu."
          alt="Rybářský spolek Radovesnice II"
        />
      </Link>

      <Slider {...sliderSettings} className="slideshow">
        {slideShow.backgrounds.nodes.map((image, index) => {
          return <GatsbyImage image={getImage(image)} key={index} alt="" />;
        })}
      </Slider>
    </div>
  );
};

export default Header;
