import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import SimpleReactLightbox from "simple-react-lightbox";

import Nav from './nav';
import Header from './header.js';

import '@wordpress/block-library/build-style/style.css';
import '../assets/css/style.css';
import copy from '../assets/img/copy.png';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        generalSettings {
          title
          url
          description
        }
      }
    }
  `);

  const { title } = data.wp.generalSettings;
  const { description } = data.wp.generalSettings;
  const { url } = data.wp.generalSettings;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={url} />
      </Helmet>
      <header className="container">
        <Header />
        <Nav />
      </header>
      <SimpleReactLightbox>
      <main className="wrapper container">{children}</main>
      </SimpleReactLightbox>
      <footer className="container">
        © 2016
        <img src={copy} alt="PS" title="Patrik Sosna" />
      </footer>
    </>
  );
};

export default Layout;
